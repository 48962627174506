import React, {useCallback, useRef, useState} from 'react';
import {toJpeg} from 'html-to-image';
import InvoicePage from './components/InvoicePage'
import { Invoice } from './data/types'
import { configData } from "./config/config";

function App() {

  const [download,setDownload] = useState(false);

  const ref = useRef<HTMLDivElement>(null)

  const onButtonClick = useCallback(() => {

      setDownload(true);

    if (ref.current === null) {
      return
    }

      toJpeg(ref.current, { quality: 1 })
          .then(function (dataUrl) {
              var link = document.createElement('a');
              link.download = 'ALMYAMEN Invoice.jpeg';
              link.href = dataUrl;
              link.click();
              setDownload(false);
          }).catch((err) => {
          setDownload(false);
          console.log(err)
          });

      //
      // toPng(ref.current, { cacheBust: true, quality: 0.3})
      //   .then((dataUrl) => {
      //     const link = document.createElement('a')
      //     link.download = '5FASH-Invoice.png'
      //     link.href = dataUrl
      //     link.click()
      //       setDownload(false);
      //   })
      //   .catch((err) => {
      //       setDownload(false);
      //       console.log(err)
      //   })
  }, [ref])


    let invoiceData;

  const savedInvoice = window.localStorage.getItem('invoiceDataJson')
    if (savedInvoice){
        invoiceData = savedInvoice
    }else {
        invoiceData = configData;
    }
  let data = null

  try {
    if (invoiceData) {
      data = JSON.parse(invoiceData)
    }
  } catch (_e) { }

  const onInvoiceUpdated = (invoice: Invoice) => {
    window.localStorage.setItem('invoiceDataJson', JSON.stringify(invoice))
  }

  return (
      <div className="app">
          <button onClick={onButtonClick} className={!download?"invoice-btn-print":"invoice-btn-print btn-loading"}>
              {!download?<i className="fa fa-download"></i>:null}
              <div className="btn-text">
               {download?"... تنزيل":"تنزيل الفاتورة"}
              </div>
          </button>
          <div className="div-invoice">
              <div ref={ref}>
                  <InvoicePage download={download} data={data} onChange={onInvoiceUpdated} />
              </div>
          </div>
      </div>
  )
}

export default App
